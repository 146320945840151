<style>

</style>
<script>
import { VclList } from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";

export default {
  props: ['cftools_id'],
  components: {
    CopyableText,
    VclList
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/identities`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.identities = data.identities;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    overridePersona(personaName, avatar) {
      this.$parent.$emit('overridePersona', personaName, avatar);
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      identities: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-12">
          <!-- Steam -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <!-- Steam -->
                    <div class="row w-100">
                      <div class="col-4">
                        <div class="text-center">
                          <i class="fab fa-steam h2 mb-0"></i>
                          <p class="text-muted mb-0">
                            {{ $t('profile.identities.steam.title') }}
                          </p>
                        </div>
                      </div>
                      <div class="col-8 w-100">
                        <span class="h6 pb-0 mb-0">
                          {{ $t('profile.identities.steam.label') }}
                        </span>
                        <CopyableText class="text-code" :text="identities.steam.steam64"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- BattlEye -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <!-- Steam -->
                    <div class="row w-100">
                      <div class="col-4">
                        <div class="text-center">
                          <img src="/be_32x32.jpg" class="h2 mb-0">
                          <p class="text-muted mb-0">
                            {{ $t('profile.identities.battleye.title') }}
                          </p>
                        </div>
                      </div>
                      <div class="col-8 w-100">
                        <span class="h6 pb-0 mb-0">
                          {{ $t('profile.identities.battleye.label') }}
                        </span>
                        <CopyableText class="text-code" :text="identities.battleye.guid"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Bohemia Interactive -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <!-- Steam -->
                    <div class="row w-100">
                      <div class="col-4">
                        <div class="text-center">
                          <img src="/bi_32x32.jpg" class="h2 mb-0">
                          <p class="text-muted mb-0">
                            {{ $t('profile.identities.bohemiainteractive.title') }}
                          </p>
                        </div>
                      </div>
                      <div class="col-8 w-100">
                        <span class="h6 pb-0 mb-0">
                          {{ $t('profile.identities.bohemiainteractive.label') }}
                        </span>
                        <CopyableText class="text-code" :text="identities.bohemiainteractive.uid"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="card">
                <div class="card-body text-center">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" width="400px"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
